<template>
  <WritingPage imageFileName="art-statement.jpeg">
    <template v-slot:titleA>ART STATEMENT</template>
    <template v-slot:titleB>TOMAS PEETERS</template>
    <template v-slot:copy>
      <p>
        At the center of my Observatories are empty mirrors collapsing your
        sense of being located within a head. These oscillating sculptures turn
        around the arrow of your attention from the world of objects you are
        looking at, to the empty space you are seeing from. As the Observatories
        collapse all space, that which is seeing suddenly becomes visible as an
        absence that has no boundaries. These sculptures are not objects to be
        looked at; they simply stand as transparent mirrors reflecting the
        infinite emptiness you are already looking out from. As the
        Observatories collapse all distance, you are no longer looking into the
        world—all of the world is looking out of you. There is seeing, but no
        one looking!
      </p>
    </template>
  </WritingPage>
</template>

<script>
import WritingPage from "@/components/WritingPage";
export default {
  components: {
    WritingPage,
  },
};
</script>

<style></style>
